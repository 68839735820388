body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color_b8b8b8: #b8b8b8;
  --color_fff: #ffffff;
  --color_0000000d: rgba(0, 0, 0, 0.02);
  --color_777: #777;
}

html,
body {
  padding: 0 !important;
  margin: 0;
  outline: none;
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;
  box-sizing: border-box;
}

#root {
  height: 100%;
  overflow: auto;
  box-sizing: content-box !important;
}

button {
  cursor: pointer;
  outline: none;
}

input,
textarea {
  outline: none;
}

input::placeholder,
textarea::placeholder {
  color: #c4c4c4;
}

#root::-webkit-scrollbar {
  width: 8px;
  height: 12px;
  background-color: rgba(51, 51, 51, 0.24);
}

#root::-webkit-scrollbar-thumb {
  height: 6px;
  background: #fff;
  border-radius: 0px;
  border-top: 3px solid rgba(51, 51, 51, 0.24);
  border-bottom: 3px solid rgba(51, 51, 51, 0.24);
}

#root::-webkit-scrollbar-corner {
  background: rgba(51, 51, 51, 0.24) !important;
}


.ant-menu-sub.ant-menu-inline {
  background-color: var(--color_fff) !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: var(--color_fff) !important;
}

.ant-menu-submenu-arrow:after,
.ant-menu-submenu-arrow:before {
  height: 1px;
}

.ant-modal-close {
  display: none;
}

.package {
  width: 100%;
  padding: 20px 24px;
  background: var(--background-color-white);
  box-shadow: 0px 5px 20px var(--color_0000000d);
  border-radius: 10px;
  margin-top: 20px;
  overflow-x: auto;
}

.custom::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: var(--color_fff);
}

.custom::-webkit-scrollbar-track {
  box-shadow: inset;
  border-radius: 10px;
  background-color: var(--color_fff);
}

.custom::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset;
  background: var(--color_b8b8b8);
}

.custom::-webkit-scrollbar-thumb:hover {
  background-color: var(--color_777);
}

/* pages */
.body {
  background-color: var(--color_fff) !important;
}

#home {
  min-width: 1920px;
  min-height: 919px;
  position: relative;
}

#home .ant-layout-header {
  background: var(--color_fff);
  padding: 0 !important;
  line-height: 60px;
}

#home .ant-layout-sider {
  background: var(--color_fff) !important;
}

#home .ant-menu-submenu-title {
  height: 70px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  padding: 0 !important;
  width: 212px;
}

#home .ant-layout-footer {
  background: transparent !important;
}

.ant-notification-notice-message {
  margin-bottom: 0;
}

.ant-notification-notice-close {
  top: 6px;
  right: 6px;
}

.ant-notification-notice {
  width: 320px;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 0;
  min-height: 80px;
}

#copycode {
  user-select: auto;
}

#home_content {
  position: relative;
}

.btn_active {
  background: #FFB140 !important;
  cursor: pointer !important;
}

.btn_active:hover {
  background: #FFBE60 !important;
  cursor: pointer !important;
}

.btn_active:active {
  background: #FFBE60 !important;
  cursor: pointer !important;
  font-weight: bold !important;
}

.btn_cancel:hover {
  background: #fff !important;
  border-color: #666666 !important;
  cursor: pointer !important;
}

.btn_cancel:active {
  background: #fff !important;
  cursor: pointer !important;
  border-color: #666666 !important;
  font-weight: bold !important;
}

.f {
  display: flex;
}

.fc {
  display: flex;
  align-items: center;
}

.fs {
  display: flex;
  justify-content: space-between;
}

.fsc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fcc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fscc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.fccc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.full {
  width: 100%;
}

.fullh {
  height: 100%;
}

/* 弹窗固定样式修改 */
.modal_global {
  user-select: none;

  .ant-modal-title {
    >div {
      box-shadow: none !important;
      padding: 29px 0 20px;
    }
  }

  .ant-modal-centered .ant-modal {
    box-shadow: 0px 4px 4px rgba(126, 126, 126, 0.1);
  }

  .ant-modal-content {
    border-radius: 5px;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-header {
    padding: 0;
    margin: 0 35px;
  }

  .ant-modal-body {
    padding: 0;
  }
}

.ant-dropdown ul {
  transform: translate(0, -2px);
}

.dropdown {
  overflow-y: auto;
  max-height: 200px;
  background-color: #fff;
  height: 100px !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
}

.dropdown::-webkit-scrollbar {
  width: 6px;
}

.dropdown::-webkit-scrollbar-thumb {
  width: 6px;
  background: rgba(51, 51, 51, 0.24);
  border-radius: 2px;
}